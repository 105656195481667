<template>
  <v-container grid-list-md>
    <v-layout
      v-if="user"
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex xs9>
            <h2>{{ `My Applications` }}</h2>
            <span class="subheading" />
          </v-flex>
        </v-layout>
        <v-divider class="my-3" />
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <h3>Start an application:</h3>
              </v-flex>
              <v-flex xs4>
                <v-btn @click="createApplication">
                  Apply for Addmission
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 md10>
            <v-data-table
              :headers="headers"
              :items="applications"
              class="elevation-1"
              hide-actions
            >
              <template v-slot:items="props">
                <td>Addmission Request</td>
                <td class="text-xs-right">
                  {{ props.item.applicationId }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.firstName }} {{ props.item.lastName }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.createDate }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.status }}
                </td>
                <td class="justify-center layout px-0">
                  <v-btn
                    v-if="props.item.status === 'incomplete' && !props.item.repId"
                    class="primary"
                    small
                    flat
                    @click="countine(props.item.id)"
                  >
                    Countine
                  </v-btn>
                  <v-menu
                    v-if="props.item.status === 'on request'"
                    offset-y
                    bottom
                    right
                  >
                    <v-btn
                      slot="activator"
                      color="info"
                      small
                      flat
                    >
                      View Request
                    </v-btn>
                    <v-card>
                      <v-card-text>
                        <b>{{ `${rep.firstName} ${rep.lastName}` }}</b> would like to take over your application.<br><br>
                        Note: Once accepted, you will not be able to make further changes to this application.
                      </v-card-text>

                      <v-card-actions>
                        <v-btn
                          text
                          small
                          flat
                          @click="declineRequest(props.item)"
                        >
                          Decline
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          small
                          flat
                          @click="acceptRequest(props.item)"
                        >
                          Accept
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </td>
              </template>
              <template v-slot:no-data>
                <v-alert
                  :value="true"
                  color="info"
                  icon="info"
                  outline
                >
                  No application has been created yet
                </v-alert>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Application',
  data() {
    return {
      user: null,
      applications: [],
      rep: {},
      headers: [
        {
          text: 'Appplication Type',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Application number',
          sortable: false,
        },
        {
          text: 'Applicant name',
          sortable: false,
        },
        {
          text: 'Date created',
          sortable: false,
        },
        {
          text: 'Current status',
          sortable: false,
        },
        {
          text: 'Action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    userId() {
      return this.$route.params.id
    },
  },
  created() {
    this.$store.dispatch('members/one', this.userId)
      .then((data) => {
        this.user = data
      })
    console.log(this.userId)
    this.$store.dispatch('members/findAppsById', this.userId)
      .then((data) => {
        console.log(data)
        if (data) this.applications = data
        if (data[0].repId) {
          this.$store.dispatch('representatives/one', data[0].repId)
          .then((rep) => {
            this.rep = rep
          })
        }
      })
  },
  methods: {
    createApplication() {
      if (this.applications.length !== 0) {
        alert('Already have application of the same type created')
        return
      }
      this.$store.dispatch('members/createApplication', this.userId)
        .then((data) => {
          this.applications.push(data)
        })
    },
    countine(id) {
      this.$router.push(`/applications/${id}`)
    },
    acceptRequest(app) {
      app.status = 'incomplete'
      this.$store.dispatch('applications/update', app)
        .then((data) => {
          this.$store.dispatch('showMessage', { state: 'success', message: 'You have accepted the take-over request' })
        })
        .catch(() => this.$store.dispatch('showMessage', { state: 'error', message: 'Failed to accept the take-over request.' }))
    },
    declineRequest(app) {
      app.repId = null
      app.status = 'incomplete'
      this.$store.dispatch('applications/update', app)
        .then((data) => {
          this.$store.dispatch('showMessage', { state: 'success', message: 'You have declined the take-over request' })
        })
        .catch(() => this.$store.dispatch('showMessage', { state: 'error', message: 'Failed to decline the take-over request.' }))
    }
  },
}
</script>

<style>
</style>
